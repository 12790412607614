
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import ExploitationContent from "@/components/exploitations/ExploitationContent.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { notify } from "@/core/helpers/globalJaya";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "exploitations",
  components: {
    ExploitationContent,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store
        .dispatch(Actions.FETCH_OTEX)
        .then(() => {
          setCurrentPageTitle("Nouvelle Exploitation");
        })
        .catch(() => {
          router.push({ name: "homepage" });
          notify({
            text: "Une erreur est survenue. Veuillez réessayer ultérieurement",
            color: "error",
          });
        });
    });
  },
});
